* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    width: 100%;
    height: 100%;
    background-color: #e9e9eb;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-image: url(https://bing.biturl.top/?resolution=1920&format=image&index=0&mkt=zh-CN); */
}

body {
    font-family: helvetica, Microsoft Yahei;
    font-size: 18px;
    /* background-image: url("https://blog.mrabit.com/bing/today"); */
    background-repeat: no-repeat;
    background-size: cover;
    overflow-x: hidden;
    /* background-color: gray; */
    background-position: center;
    background-attachment: fixed;
    overflow-x: hidden;
    height: 100%;
}

body::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent url(https://bing.biturl.top/?resolution=1920&format=image&index=0&mkt=zh-CN) center center no-repeat;
    filter: blur(7px);
    z-index: -1;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    margin: 0 0;
    -webkit-filter: blur(7px);
    -moz-filter: blur(7px);
    -ms-filter: blur(7px);
    -o-filter: blur(7px);
}

button {
    border: 0;
    outline: 0;
}

.container {
    margin: 40px 0;
    width: 400px;
    height: 600px;
    padding: 10px 25px;
    background: #43506c;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.45), 0 4px 8px rgba(0, 0, 0, 0.35), 0 8px 12px rgba(0, 0, 0, 0.15);
    font-family: "Montserrat";
}

.container h2.title {
    font-size: 1.75rem;
    margin: 10px -5px;
    margin-bottom: 30px;
    color: #fff;
}

.result {
    position: relative;
    width: 100%;
    height: 65px;
    overflow: hidden;
}

.result__info {
    position: absolute;
    bottom: 4px;
    color: #fff;
    font-size: 0.8rem;
    transition: all 150ms ease-in-out;
    transform: translateY(200%);
    opacity: 0;
}

.result__info.right {
    right: 8px;
}

.result__info.left {
    left: 8px;
}

.result__viewbox {
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.08);
    border-radius: 8px;
    color: #fff;
    text-align: center;
    line-height: 65px;
}

.result #copy-btn {
    position: absolute;
    top: var(--y);
    left: var(--x);
    width: 38px;
    height: 38px;
    background: #fff;
    border-radius: 50%;
    opacity: 0;
    transform: translate(-50%, -50%) scale(0);
    transition: all 350ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    cursor: pointer;
    z-index: 2;
}

.result #copy-btn:active {
    box-shadow: 0 0 0 200px rgba(255, 255, 255, 0.08);
}

.result:hover #copy-btn {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1.35);
}

.field-title {
    position: absolute;
    top: -10px;
    left: 8px;
    transform: translateY(-50%);
    font-weight: 800;
    color: rgba(255, 255, 255, 0.5);
    text-transform: uppercase;
    font-size: 0.65rem;
    pointer-events: none;
    user-select: none;
}

.options {
    width: 100%;
    height: auto;
    margin: 50px 0;
}

.range__slider {
    position: relative;
    width: 100%;
    height: calc(65px - 10px);
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.08);
    border-radius: 8px;
    margin: 30px 0;
}

.range__slider::before,
.range__slider::after {
    position: absolute;
    color: #fff;
    font-size: 0.9rem;
    font-weight: bold;
}

.range__slider::before {
    content: attr(data-min);
    left: 10px;
}

.range__slider::after {
    content: attr(data-max);
    right: 10px;
}

.range__slider .length__title::after {
    content: attr(data-length);
    position: absolute;
    right: -16px;
    font-variant-numeric: tabular-nums;
    color: #fff;
}

#slider {
    -webkit-appearance: none;
    width: calc(100% - (70px));
    height: 2px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.314);
    outline: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
}

#slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
    transition: all 0.15s ease-in-out;
}

#slider::-webkit-slider-thumb:hover {
    background: #d4d4d4;
    transform: scale(1.2);
}

#slider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border: 0;
    border-radius: 50%;
    background: white;
    cursor: pointer;
    transition: background 0.15s ease-in-out;
}

#slider::-moz-range-thumb:hover {
    background: #d4d4d4;
}

.settings {
    position: relative;
    height: auto;
    widows: 100%;
    display: flex;
    flex-direction: column;
}

.settings .setting {
    position: relative;
    width: 100%;
    height: calc(65px - 10px);
    background: rgba(255, 255, 255, 0.08);
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 10px 25px;
    color: #fff;
    margin-bottom: 8px;
}

.settings .setting input {
    opacity: 0;
    position: absolute;
}

.settings .setting input+label {
    user-select: none;
}

.settings .setting input+label::before,
.settings .setting input+label::after {
    content: "";
    position: absolute;
    transition: 150ms cubic-bezier(0.24, 0, 0.5, 1);
    transform: translateY(-50%);
    top: 50%;
    right: 10px;
    cursor: pointer;
}

.settings .setting input+label::before {
    height: 30px;
    width: 50px;
    border-radius: 30px;
    background: rgba(214, 214, 214, 0.434);
}

.settings .setting input+label::after {
    height: 24px;
    width: 24px;
    border-radius: 60px;
    right: 32px;
    background: #fff;
}

.settings .setting input:checked+label:before {
    background: #ef4b4c;
    transition: all 150ms cubic-bezier(0, 0, 0, 0.1);
}

.settings .setting input:checked+label:after {
    right: 14px;
}

.settings .setting input:focus+label:before {
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.75);
}

.btn.generate {
    user-select: none;
    position: relative;
    width: 100%;
    height: 50px;
    margin: 10px 0;
    border-radius: 8px;
    color: #fff;
    border: none;
    background-color: #3d6198;
    letter-spacing: 1px;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 150ms ease;
}

.btn.generate:active {
    transform: translateY(-3%);
    box-shadow: 0 4px 8px rgba(255, 255, 255, 0.08);
}

.support {
    position: fixed;
    right: 10px;
    bottom: 10px;
    padding: 10px;
    display: flex;
}

a {
    margin: 0 20px;
    color: #fff;
    font-size: 2rem;
    transition: all 400ms ease;
}

a:hover {
    color: #222;
}